<template>
<div class="administrationpartner-container">
    <vs-dialog blur prevent-close v-model="active">
        <template #header>
            <h4 class="not-margin">
                Crea account <b>partner</b>
            </h4>
        </template>

        <div class="con-form">
            <vs-input :disabled="loading" v-model="email" label="Partner Email" placeholder="partner@example.com" block/>
        </div>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="createPartner" :loading="loading">
                    {{ $t('common.createNew') }}
                </vs-button>

                <vs-button danger border  block @click="deletePartner" :loading="deleting">
                    {{ $t('common.delete') }}
                </vs-button>

                
            </div>
        </template>
    </vs-dialog>
</div>
</template>

<script>
import {
    apiCall
} from '../../utils/ApiMiddleware';
export default ({
    data() {
        return {
            loading: false,
            deleting: false,
            email: '',
            active: true
        }
    },
    props: {
        user: {
            type: Object,
            required: false
        }
    },

    mounted() {

    },

    methods: {

        async createPartner() {
            this.loading = true;
            const response = await apiCall('POST', '/backoffice/users/partner', {
                email: this.email
            });
            this.loading = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {

                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Account partner creato con successo.',
                    color: 'success',
                    position: 'top-right'
                });
                this.email = '';
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile creare l\'account partner.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async deletePartner() {
            this.deleting = true;
            const response = await apiCall('DELETE', '/backoffice/users/partner/' + this.email);
            this.deleting = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {

                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Account partner eliminato con successo.',
                    color: 'success',
                    position: 'top-right'
                });
                this.email = '';
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare l\'account partner.',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        }
    },

    watch: {
        active(n){
            if(!n){
                this.$router.go(-1);
            }
        }
    }
})
</script>

<style scoped>
.administrationpartner-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
